import React, {Fragment} from "react";
import {
    FacebookIcon,
    FacebookShareButton, InstapaperIcon,
    InstapaperShareButton, LinkedinIcon,
    LinkedinShareButton, TwitterIcon,
    TwitterShareButton
} from "react-share";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    icon: {
        cursor: 'pointer'
    }
}));

const SocialButtons = () => {
    const classes = useStyles();
    const url = decodeURIComponent(window.location.href);
    return <Grid container spacing={1}>
                <Grid item>
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={32} round className={classes.icon}/>
                    </FacebookShareButton>
                </Grid>
                <Grid item>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={32} round className={classes.icon}/>
                    </TwitterShareButton>
                </Grid>
            <Grid item>
                <LinkedinShareButton url={url}>
                    <LinkedinIcon size={32} round className={classes.icon}/>
                </LinkedinShareButton>
            </Grid>
            <Grid item>
                <InstapaperShareButton url={url}>
                    <InstapaperIcon size={32} round className={classes.icon}/>
                </InstapaperShareButton>
            </Grid>
    </Grid>;
}

export default SocialButtons;