import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, {Fragment} from "react";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import ContentLoader from "react-content-loader";
import {extractExcerptFromRichText, formatToMultiline} from "../util";
import config from '../config';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    title: {
        fontSize: 20,
    },
    definition: {
        whiteSpace: 'pre-wrap',
        paddingRight: theme.spacing(1)
    },
    '@global': {
        '.SHRT': { fontSize: 14, color: 'gray' },
        '.word-link': {
            textDecoration: 'underline',
            color: '#7D0414',
        }
    }
}));

function displayDefinitionExcerpt(exactMatch) {
    //let definition = formatToMultiline(exactMatch.wordDefinition).replace(/<span.+?data\-short\="(.+?)".*?\/span>/g, '$1.');
    let exerpt = extractExcerptFromRichText(formatToMultiline(exactMatch.wordDefinition), 300);
    exerpt += '...';
    return exerpt;
}

function renderTopWords(classes, topWords) {
    if(topWords.length === 0){
        return <ContentLoader
            height={160}
            width={400}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="5" y="35" rx="0" ry="0" width="100%" height="500"/>
            <rect x="5" y="540" rx="540" ry="0" width="100%" height="500"/>
        </ContentLoader>;
    }
    return <List className={classes.root}>
        {
            topWords.map((item, index) => (
                <Fragment key={`${item.word}_${index}`}>
                    <Divider component="li"/>
                    <ListItem alignItems="flex-start">
                        <ListItemText className={classes.definition}
                            primary={<Link color="primary" component={RouterLink}
                                           to={`/${item.word}`}>{item.word}</Link>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {item.exactMatches && <span key={`${item.word}${index}`} className={classes.definition} dangerouslySetInnerHTML={{__html: displayDefinitionExcerpt(item.exactMatches[0])}}/>}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                        <ListItemSecondaryAction style={{top: '90%'}}><Link href={`${item.word}`}>Më shumë</Link></ListItemSecondaryAction>
                    </ListItem>
                </Fragment>))
        }

    </List>;
}

export default function MostSearched(props) {
    const classes = useStyles();
    const [topWords, setTopWords] = React.useState([]);
    React.useEffect(() => {
        axios.get(config.topTrendingWordsUrl)
            .then(function (response) {
                let topWordsUnique = response.data;
                //const day = (new Date().getDay() + 2) % 7;
                // setTopWords(topWordsUnique.slice(day, day + 3));
                const len = Math.min(topWordsUnique.length, 5);
                setTopWords(topWordsUnique.slice(0, len));
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }, []);
    return <Paper elevation={1}>
            <Card className={classes.card}>
                <div className={classes.cardDetails}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                            Më të kërkuarat së fundmi
                        </Typography>
                        {renderTopWords(classes, topWords)}
                    </CardContent>
                </div>
            </Card>
        </Paper>;
}