import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import {fade, makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import WordAutosuggest from "./WordAutosuggest";
import {Link as RouterLink} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import MenuBookTwoToneIcon from "@material-ui/icons/MenuBookTwoTone";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    homeIcon:{
        color:'white'
    },
    search: {
        position: 'relative',
        height: '38px',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade('#bbc3c7', 0.15),
        '&:hover': {
            backgroundColor: fade('#bbc3c7', 0.25),
        },
        marginLeft: 15,
        marginRight: theme.spacing(1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    navigationSubtitle: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    },
    appBarTitle: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    notification:{
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        },
        fontSize: 18
    }
}));

export default function ConjugationHeader(props) {
    const classes = useStyles();
    const [verbValue, setVerbValue] = React.useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleConjugate(verbValue)
    };
    let loading = props.loading;
    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <AppBar position="static">
                    <Toolbar>
                        <Link className={classes.homeIcon} component={RouterLink} to="/">
                            <HomeIcon />
                        </Link>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <WordAutosuggest type="verb"/>
                        </div>
                        <Typography variant="subtitle1" gutterBottom className={classes.appBarTitle}>
                            Zgjedhuesi i foljeve
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Grid item xs="12" md="6" className={classes.navigationSubtitle}>
                    <Grid container spacing={1} direction="row" alignItems="center">
                        <Grid item>
                            <MenuBookTwoToneIcon color="primary"/>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.notification}>
                               <Link component={RouterLink} to="/">Kthehu</Link> te Fjalori
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
