import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, {Fragment} from "react";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import ContentLoader from "react-content-loader";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import jsonp from 'jsonp';
import queryString from 'query-string';
import {green, red} from "@material-ui/core/colors";
import {formatToMultiline} from "../util";
import config from '../config';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    title: {
        fontSize: 20,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    subscriptionError: {
        padding: theme.spacing(2),
        color: theme.palette.error.dark
    },
    subscriptionSuccess: {
        padding: theme.spacing(2),
        color: green[600]
    },
    definition: {
        whiteSpace: 'pre-wrap'
    },
    '@global': {
        '.SHRT': { fontSize: 14, color: 'gray' }
    }
}));

function renderWordOfTheDay(classes, wordOfTheDay) {
    if(!wordOfTheDay || Object.keys(wordOfTheDay).length === 0){
        return <ContentLoader
            height={160}
            width={400}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="5" y="35" rx="0" ry="0" width="100%" height="500"/>
            <rect x="5" y="540" rx="540" ry="0" width="100%" height="500"/>
        </ContentLoader>;
    }
    return <List className={classes.root}>
            <Fragment>
                <Divider component="li"/>
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={<Link color="primary" component={RouterLink}
                                       to={`/${wordOfTheDay.word}`}>{wordOfTheDay.word}</Link>}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    <span className={classes.definition} dangerouslySetInnerHTML={{__html: formatToMultiline(wordOfTheDay.definition)}}/>
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </Fragment>
    </List>;
}

function renderSubscriptionSection(classes, setEmail, subscriptionResult, subscribeHandler) {
    let content = [];
    if(Object.keys(subscriptionResult).length === 0){
        content.push(
            <Grid container key="subscriptionSection1">
                <Grid item xs={12} md={7}>
                    <TextField
                        id="standard-helperText"
                        label="Email-i Juaj"
                        className={classes.textField}
                        helperText="Do të pranoni fjalën e ditës në email-in tuaj"
                        onChange={e => setEmail(e.target.value)}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={4} className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" size="small" onClick={subscribeHandler}>
                        Abonohu
                    </Button>
                </Grid>
            </Grid>);
    }
    else if(subscriptionResult.result === 'error'){
        content.push(<Grid item xs={12}><Typography className={classes.subscriptionError} component="h4"> {subscriptionResult.msg.indexOf('veçse') != -1 ? 'Ju veçse jeni abonuar!' : subscriptionResult.msg}</Typography></Grid>);
    }
    else if(subscriptionResult.result === 'success'){
        content.push(<Grid item xs={12}><Typography className={classes.subscriptionSuccess}  component="h4"> {subscriptionResult.msg}</Typography></Grid>);
    }
    return <Grid container>{content}</Grid>;
}

export default function WordOfTheDay(props) {
    const classes = useStyles();
    const [email, setEmail] = React.useState('');
    const [subscriptionResult, setSubscriptionResult] = React.useState({});
    const [wordOfTheDay, setWordOfTheDay] = React.useState([]);
    React.useEffect(() => {
        axios.get(config.apiWordOfTheDayUrl)
            .then(function (response) {
                setWordOfTheDay(response.data);
                //props.countrySetHandler(response.data.country);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }, []);
    const subscribeHandler = () => {
        const formData = {'EMAIL': email};
        const u = '86449b49eb11cd498aa9be4aa';
        const id = '8912aa19a3';
        jsonp(`https://gmail.us4.list-manage.com/subscribe/post-json?u=${u}&id=${id}&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
            setSubscriptionResult(err && Object.keys(err).length > 0 ? err : data);
        });
    };
    return <Paper elevation={1}>
            <Card className={classes.card}>
                <div className={classes.cardDetails}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                            Fjala e Ditës
                        </Typography>
                        {renderWordOfTheDay(classes, wordOfTheDay)}
                        {/*<Divider component="hr"/>*/}
                        <CardActions>
                            {renderSubscriptionSection(classes, setEmail, subscriptionResult, subscribeHandler)}
                        </CardActions>
                    </CardContent>
                </div>
            </Card>
    </Paper>;
}