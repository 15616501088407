/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import MostSearched from "../components/MostSearched";
import WordOfTheDay from "../components/WordOfTheDay";
import {FacebookProvider, Page} from "react-facebook";

const styles = theme => ({
    mainFeaturedPost: {
        /* backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white, */
        marginBottom: theme.spacing(4),
    },
    mainFeaturedPostContent: {
        padding: `${theme.spacing(2)}px`,
        [theme.breakpoints.up('md')]: {
            paddingRight: 0,
        }
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    }
});

class HomePage extends React.Component {
    state = {
        open: false,
    };

    render() {
        const {classes} = this.props;
        const {open} = this.state;

        return (
            <React.Fragment>
                <Grid container className={classes.cardGrid} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <MostSearched/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <WordOfTheDay countrySetHandler={this.props.countrySetHandler}/>
                    </Grid>
                    <Grid item xs={12}>
{/*                        <FacebookProvider appId="123456789">
                            <Page href="https://www.facebook.com" tabs="timeline" />
                        </FacebookProvider>*/}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePage);
