import React, {useEffect} from 'react';

import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import ConjugationHeader from "../components/ConjugationHeader";
import axios from "axios";
import config from "../config";
import MoodCard, {ConjugationLoader, prettify} from "../components/MoodCard";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import {CardActions, CardHeader, Divider} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import * as ReactGA from "react-ga";
import SocialButtons from "../components/SocialButtons";
import WordOfTheDay from "../components/WordOfTheDay";
import MostSearched from "../components/MostSearched";

export function ConjugationPage(props){
    const classes = useStyles(props);
    const [verb, setVerb] = React.useState('');
    const [cvTreeList, setConjugatedVerb] = React.useState(null);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [voice, setVoice] = React.useState('AC')
    const [availableVoices, setAvailableVoices] = React.useState(['AC', 'PS']);
    const fetchConjugations = function(verbValue) {
        setLoading(true);
        axios.get(`${config.conjugateVerb}/${verbValue}`)
            .then(function (response) {
                setConjugatedVerb(response.data);
                let voices = []
                let firstPersonActive = response.data[0]['MOOD']['INDICATIVE']['PRESENT']['AC']['I_S'];
                let firstPersonPassive = response.data[0]['MOOD']['INDICATIVE']['PRESENT']['PS']['I_S'];
                let thirdPersonActive = response.data[0]['MOOD']['INDICATIVE']['PRESENT']['AC']['III_S'];
                let thirdPersonPassive = response.data[0]['MOOD']['INDICATIVE']['PRESENT']['PS']['III_S'];
                if(firstPersonActive || (thirdPersonActive && !firstPersonActive)){
                    voices.push('AC');
                }
                if(firstPersonPassive || (thirdPersonPassive && !firstPersonActive)){
                    voices.push('PS');
                }
                setAvailableVoices(voices);
                setVoice(voices[0]);
            })
            .catch(function (error) {
                setError('Diçka shkoi keq gjatë kërkimit! Kontrolloni lidhjen me internet')
                console.log(error);
            })
            .finally(function() {
                setLoading(false);
                ReactGA.pageview(window.location.pathname + window.location.search);
                document.title = `${extractVerbFromPath()} | Zgjedhimi në të gjitha mënyrat dhe kohët`;
            });
    }
    useEffect(() => {
        let verb = extractVerbFromPath();
        if(verb && verb.length > 1){
            fetchConjugations(verb);
        }
        else{
            document.title = 'Zgjedhuesi i foljeve';
        }
    }, [props.match.params[0]]);

    const extractVerbFromPath = function(){
        let verbInPath = props.match.params[0];
        if(verbInPath){
            return verbInPath.replace(/\//, '');
        }
        return '';
    }

    const handleConjugate = (verbValue) => {
        setVerb(verbValue);
        setLoading(true);
        //fetchConjugations(verbValue)
    }
    const changeVoice = (e) => {
        setVoice(e.target.value);
    }
    let isThirdPersonOnly = false;
    let conjugationCards = [];
    if(loading){
        conjugationCards.push(
            <React.Fragment>
                <Grid item xs={12} >
                    <Paper elevation={1}>
                        <Card className={classes.headerCard} variant="outlined">
                            <CardContent style={{paddingBottom: 0}}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.title}>
                                            Zgjedhimi i foljes <strong>{extractVerbFromPath()}</strong>
                                        </Typography>
                                        <Divider/>
                                        <ConjugationLoader/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <MoodCard loading verb={verb} voice={voice} moodName="INDICATIVE" />
                    <MoodCard loading verb={verb} voice={voice} moodName="SUBJUNCTIVE"  />
                    <MoodCard loading verb={verb} voice={voice} moodName="ADMIRATIVE" />
                    <MoodCard loading verb={verb} voice={voice} moodName="OPTATIVE" />
                    <MoodCard loading verb={verb} voice={voice} moodName="CONDITIONAL" />
                    <MoodCard loading verb={verb} voice={voice} moodName="IMPERATIVE" />
                </Grid>
            </React.Fragment>
        )
    }
    else if (!cvTreeList){
        conjugationCards.push(
            <React.Fragment>
                <Grid item xs={12} >
                    <Paper elevation={2}>
                        <Card className={classes.headerCard} variant="outlined">
                            <CardContent >
                                <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                                    Zgjedhues i foljeve
                                </Typography>
                                <Divider/>
                                <Typography variant="subtitle1" className={classes.subtitle}>
                                    Për të parë zgjedhimin e foljes, shkruaj apo zgjidh atë në shiritin e mësipërm ku shkruan <i>"Shkruaj foljen këtu".</i> Mënyrat dhe kohët do të shfaqen këtu
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </React.Fragment>
);
    }
    else if(cvTreeList && cvTreeList.length > 0){
        for(let cTree of cvTreeList){
            let conjugatedPresent = cTree['MOOD']['INDICATIVE']['PRESENT'];
            isThirdPersonOnly = !(conjugatedPresent['PS']['I_S'] || conjugatedPresent['AC']['I_S']);
            conjugationCards.push(
                <React.Fragment>
                    <Grid item xs={12} >
                        <Paper elevation={2}>
                            <Card className={classes.headerCard} variant="outlined">
                                <CardContent style={{paddingBottom: 0}}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                                                Zgjedhimi i foljes <strong>{prettify(cTree['VERB'])}</strong>
                                            </Typography>
                                            {cTree['TYPE'].map(item => <Chip className={classes.chipVerbType} size="small" color="default" label={i18n[item]} />)}
                                            <Divider/>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} className={classes.verbMeta}>
                                                    <Grid item>
                                                        <Typography variant="body1" gutterBottom>
                                                            Pjesorja: <Chip size="small" className={classes.chip} color="primary" label={cTree['PARTICIPLE']} />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" gutterBottom>
                                                            Paskajorja: <Chip size="small" className={classes.chip} color="primary" label={cTree['INFINITIVE'][voice]} />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1" gutterBottom>
                                                            Përcjellorja: <Chip size="small" className={classes.chip} color="primary" label={cTree['GERUND'][voice]} />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            <Grid direction="row" conainer>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup row aria-label="Diateza" name="voice" value={voice} onChange={changeVoice}>
                                                            <FormControlLabel value="AC" control={<Radio />} disabled={!availableVoices.includes('AC')} label="Veprore" />
                                                            <FormControlLabel value="PS" control={<Radio />} disabled={!availableVoices.includes('PS')} label="Joveprore" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                    {/*<Divider orientation="vertical" flexItem />*/}

                                                    {/*<Divider orientation="vertical" flexItem />*/}

                                                </Grid>
                                            </Grid>
                                            {/*<Divider orientation="vertical" flexItem />*/}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <MoodCard loading={loading} verb={verb} voice={voice} moodName="INDICATIVE" isThirdPersonOnly={isThirdPersonOnly} mood={cTree['MOOD']['INDICATIVE']}/>
                        <MoodCard loading={loading} verb={verb} voice={voice} moodName="SUBJUNCTIVE" isThirdPersonOnly={isThirdPersonOnly} mood={cTree['MOOD']['SUBJUNCTIVE']}/>
                        <MoodCard loading={loading} verb={verb} voice={voice} moodName="ADMIRATIVE" isThirdPersonOnly={isThirdPersonOnly} mood={cTree['MOOD']['ADMIRATIVE']}/>
                        <MoodCard loading={loading} verb={verb} voice={voice} moodName="OPTATIVE" isThirdPersonOnly={isThirdPersonOnly} mood={cTree['MOOD']['OPTATIVE']}/>
                        <MoodCard loading={loading} verb={verb} voice={voice} moodName="CONDITIONAL" isThirdPersonOnly={isThirdPersonOnly} mood={cTree['MOOD']['CONDITIONAL']}/>
                        <MoodCard loading={loading} verb={verb} voice={voice} moodName="IMPERATIVE" isThirdPersonOnly={isThirdPersonOnly} mood={cTree['MOOD']['IMPERATIVE']}/>
                    </Grid>
                </React.Fragment>);
        }

    }
    else {
        conjugationCards.push(
            <React.Fragment>
                <Grid item xs={12} >
                    <Paper elevation={2}>
                        <Card className={classes.headerCard} variant="outlined">
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                                    Zgjedhimi i foljes <strong>{extractVerbFromPath()}</strong>
                                </Typography>
                                <Divider/>
                                <div className={classes.subtitle}>
                                    <Typography variant="p">
                                        Folja e kërkuar "{extractVerbFromPath()}" nuk u gjet!
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <ConjugationHeader {...props} handleConjugate={handleConjugate} loading={loading} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {conjugationCards}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid item>
                        <WordOfTheDay/>
                    </Grid>
                    {/*<Grid item className={classes.withTopMargin}>*/}
                    {/*    <MostSearched/>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    headerCard: {
        minWidth: 275,
        marginBottom: theme.spacing(1),
        // marginTop: theme.spacing(1)
    },
    title: {
        fontSize: 20,
        marginBottom: theme.spacing(1.5),
    },
    notFound:{
        marginTop: theme.spacing(1.5),
        fontSize: 18
    },
    moodCard: {
        marginBottom: theme.spacing(2)
    },
    verbMeta:{
        marginTop: theme.spacing(0.2),
    },
    chip: {
        margin: theme.spacing(0.2),
    },
    subtitle: {
        // margin: theme.spacing(1),
        fontSize: 16,
        padding: theme.spacing(1)
    },
    chipVerbType: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    withTopMargin: {
        marginTop: theme.spacing(2)
    },
}));

const i18n = {
    'trans': 'Kalimtare',
    'intrans': 'Jokalimtare',
    'refl': 'Vetvetore',
    'III': 'v. III'
}