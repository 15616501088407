import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {fade, makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import WordAutosuggest from "./WordAutosuggest";
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import Grid from "@material-ui/core/Grid";
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    /*     flexGrow: 1, */
/*    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },*/
    color:'white'
  },
  search: {
    position: 'relative',
    height: '38px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#bbc3c7', 0.15),
    '&:hover': {
      backgroundColor: fade('#bbc3c7', 0.25),
    },
    marginLeft: 15,
    marginRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(3),
    height: '100%',
    left: theme.spacing(1),
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  appBarTitle: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  navigationSubtitle: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  notification:{
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    },
    fontSize: 18
  }
}));

export default function Header(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Link className={classes.title} component={RouterLink} to="/">
            <HomeIcon />
          </Link>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <WordAutosuggest />
          </div>
          <Typography className={classes.appBarTitle} variant="subtitle1" color="inherit">
            Fjalor i Gjuhës Shqipe (ASHSH 2006)
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.navigationSubtitle} spacing={1} xs="12" direction="row" alignItems="center">
        <Grid item>
          <MenuBookTwoToneIcon color="primary"/>
        </Grid>
        <Grid item >
          <Typography variant="subtitle1" color="inherit" className={classes.notification}>
            Fjalorthi sjell <Link component={RouterLink} to="/zgjedho">Zgjedhuesin</Link> e foljeve!
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}