import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(6))]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    toolbarMain: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        margin: theme.spacing(2)
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarGrid: {
        justifyContent: 'space-between'
    },
    toolbarUser: {
        textAlign: 'right'
    },
    footer: {
        /*backgroundColor: theme.palette.background.paper,*/
        marginTop: theme.spacing(8),
        padding: theme.spacing(6, 0),
    },
});

function MadeWithLove() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Qëndisur me dashuri nga '}
            <Link color="inherit" href="https://fonemaks.com">
                 Fonema
            </Link>
        </Typography>
    );
}

const Footer = (props) => {
    const {classes} = props;
    const dm = 'fjalorthi.com';
    const[contactClicked, setContactClicked] = React.useState(false);
    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom>
                    Fjalor i Gjuhës Shqipe
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    {`©${new Date().getFullYear()}`} www.fjalorthi.com - Të gjitha të drejtat e rezervuara
                    <span style={{marginLeft: 10, color: '#7D0414'}}>
                        {contactClicked === true ? 'info' + '@' + dm : <a href="#" onClick={(e) => {e.preventDefault(); setContactClicked(true)}}> Kontakti</a>}
                    </span>
                </Typography>

                {/*<MadeWithLove />*/}
            </Container>
        </footer>    );
}

export default withStyles(styles)(Footer);