import React, {Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {withStyles} from '@material-ui/core/styles';
import MostSearched from "../components/MostSearched";
import axios from "axios";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link as RouterLink} from 'react-router-dom';
import InBooks from "../components/InBooks";
import ContentLoader from "react-content-loader";
import Link from "@material-ui/core/Link";
import {formatToMultiline} from "../util";
import config from '../config';
import * as ReactGA from "react-ga";
import WordOfTheDay from "../components/WordOfTheDay";
import SocialButtons from "../components/SocialButtons";
import {CardActions} from "@material-ui/core";
import notebookImg from '../notebook.png'
import Cookies from 'js-cookie';
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    cardExact: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardSimilar: {
        marginTop: theme.spacing(2)
    },
    title: {
        fontSize: 20,
    },
    definition: {
        fontSize: 16,
        whiteSpace: 'pre-wrap'
    },
    fuzzyDefinition: {
        whiteSpace: 'pre-wrap'
    },
    wordToVerb: {
        fontSize: 16,
    },
    synonymTitle: {
        fontSize: 18
    },
    synonymContent: {
        fontSize: 16
    },
    errorMsg: {
        padding: theme.spacing(2),
        color: theme.palette.error.dark
    },
    '@global': {
        '.SHRT': { fontSize: 14, color: 'gray' },
        '.word-link': {
            textDecoration: 'underline',
            color: '#7D0414',
        }
    },
    mainWord: {
        fontWeight: '450'
    },
    withTopMargin: {
        marginTop: theme.spacing(2)
    },
    socialButtonsContainer: {
        padding: theme.spacing(2)
    }
});

function DefinitionLoader() {
    return (<ContentLoader
        height={160}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="5" y="10" rx="0" ry="0" width="136" height="15" />
        <rect x="5" y="35" rx="0" ry="0" width="100%" height="96" />
    </ContentLoader>)
};

class SearchResultPage extends React.Component {
    state = {
        word: this.props.match.params[0],
        exactMatches: [],
        fuzzyMatches: [],
        definitionLoading: this.props.loading,
        highlightLoading: this.props.loading,
        country: 'Albania'
    }

    componentDidMount() {
        this.fetchWordDefinition();
    }

    countrySetHandler = (country) => {
        this.setState({country: country});
    }

    fetchWordDefinition() {
        let comp = this;
        this.setState({ definitionLoading: true });
        const word = this.props.match.params[0];
        axios.get(`${config.apiDefineUrl}/${word}`)
            .then(function (response) {
                comp.afterFetch(response);
            })
            .catch(function (error) {
                comp.setState({errorMsg: 'Diçka shkoi keq gjatë kërkimit! Kontrolloni lidhjen me internet'})
                console.log(error);
            })
            .finally(function () {
                comp.setState({ definitionLoading: false });
                document.title = `${comp.props.match.params[0]} | Kuptimi dhe Shpjegimi në Fjalorin Shqip`;
                ReactGA.pageview(window.location.pathname + window.location.search);
                let historyList = [];
                const previouslySearched = Cookies.get('previously_searched');
                if(previouslySearched){
                    historyList = JSON.parse(previouslySearched);
                }
                const index = historyList.indexOf(word);
                if (index > -1) {
                    historyList.splice(index, 1);
                }
                if(historyList.length >= 10){
                    historyList = historyList.slice(1, 10);;
                }
                historyList.push(word);
                Cookies.set('previously_searched', JSON.stringify(historyList), { expires: 30 });
                //comp.addAdvertsToArticle();
            });
    }

    addAdvertsToArticle() {
        let advertsInArticle = document.getElementById('advert-in-article');
        while (advertsInArticle.firstChild) {
            advertsInArticle.removeChild(advertsInArticle.lastChild);
        }
        const mgidDiv = document.createElement('div');
        mgidDiv.id = 'M649456ScriptRootC1026076';
        const script = document.createElement("script");
        script.src = "https://jsc.mgid.com/f/j/fjalorthi.com.1026076.js";
        script.async = true;
        advertsInArticle.appendChild(mgidDiv);
        advertsInArticle.appendChild(script);
    }

    addCanonicalLinkIfPresent(exactMatches) {
        const existingLink = document.getElementById('canonicalLink');
        if(existingLink){
            existingLink.parentNode.removeChild(existingLink);
        }
        if(exactMatches.length > 0){
            for(let match of exactMatches){
                if(match.canonicalWord && this.state.word != match.canonicalWord){
                    const link = document.createElement('link');
                    link.id = 'canonicalLink';
                    link.rel = 'canonical';
                    link.href = `https://fjalorthi.com/${match.canonicalWord}`;
                    document.head.appendChild(link);
                }
            }
        }
    }

    appendOgMetaTags(){
        const word = this.props.match.params[0];
        let existing = document.getElementsByClassName('og-meta-tag');
        while(existing.length > 0){
            existing[0].parentNode.removeChild(existing[0]);
        }
        const appendMetaTag = (property, content) => {
            let meta = document.createElement('meta');
            meta.setAttribute('property', property);
            meta.setAttribute('class','og-meta-tag');
            meta.content = content;
            document.getElementsByTagName('head')[0].appendChild(meta);
        }
        appendMetaTag('og:title', 'Fjalor Shqip');
        appendMetaTag('og:description', `Kuptimi i fjalës ${word}`);
        let canvas = document.createElement('canvas');
        canvas.width = 415;
        canvas.height = 242;
        const newX = (canvas.width - word.length) / 2 + 10;
        const newY = (canvas.height) / 2 + 10;
        let context = canvas.getContext("2d");
        const img = new Image;
        img.src = notebookImg;
        img.onload = function(){  // ...then set the onload handler...
            context.drawImage(img,0,0);
            context.save();
            context.translate(newX, newY);
            context.rotate(-Math.PI/120);
            context.textAlign = "center";
            context.fillStyle = "#7D0414";
            context.font = "3.5em Roboto";
            context.fillText(word, 0, 0);
            context.restore();
            const base64EncodedImg = canvas.toDataURL();
            appendMetaTag('og:image', `data:image/jpg;base64,\\${base64EncodedImg}\\  />`);
            appendMetaTag('og:image:width', '415');
            appendMetaTag('og:image:height', '242');
            appendMetaTag('twitter:card', `data:image/jpg;base64,\\${base64EncodedImg}\\  />`);
        };
        //document.body.appendChild(canvas);
    }


    componentDidUpdate(prevProps) {
        if (this.props.match.params[0] !== this.state.word) {
            this.setState({ word: this.props.match.params[0] });
            this.fetchWordDefinition();
        }
    }

    afterFetch = (response) => {
        this.setState({ exactMatches: response.data.exactMatches, fuzzyMatches: response.data.fuzzyMatches });
        this.addCanonicalLinkIfPresent(response.data.exactMatches);
        //this.appendOgMetaTags();
        window.scrollTo(0, 0)
    }


    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Grid item >
                            {this.buildExactMatchesSection(classes)}
                        </Grid>
                        <div id="advert-in-article">
                        </div>
                        <Grid item className={classes.withTopMargin}>
                            <InBooks word={this.props.match.params[0]} definitionLoading={this.state.definitionLoading} exactMatches={this.state.exactMatches} />
                        </Grid>
                        <Grid item>
                            {this.buildFuzzyMatchesSection(classes)}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid item>
                            <WordOfTheDay countrySetHandler={this.countrySetHandler}/>
                        </Grid>
                        {/*<Grid item className={classes.withTopMargin}>*/}
                        {/*    <MostSearched/>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    buildAntonymsArea(classes, antonyms) {
        const links = [];
        antonyms.map((antonym, index) => {
            if (index > 0) {
                links.push(<span>  |  </span>)
            }
            links.push(<Link color="primary" component={RouterLink} to={`/${antonym}`}> {antonym} </Link>);
        })
        return (
            <div>
                <Divider variant="middle" />
                <ListItem>
                    <ListItemText classes={{ primary: classes.synonymTitle, secondary: classes.synonymContent }} primary="Antonime" secondary={links} />
                </ListItem>
            </div>);
    }

    buildSynonymsArea(classes, synonyms) {
        const links = [];
        synonyms.map((synonym, index) => {
            if (index > 0) {
                links.push(<span key={`pipe${index}`}>  |  </span>)
            }
            links.push(<Link key={`${synonym}${index}`} color="primary" component={RouterLink} to={`/${synonym}`}> {synonym} </Link>);
        })
        return (
            <div key="synonyms">
                <Divider variant="middle" />
                <ListItem>
                    <ListItemText classes={{ primary: classes.synonymTitle, secondary: classes.synonymContent }} primary="Sinonime / Të ngjashme" secondary={links} />
                </ListItem>
            </div>);
    }

    createMarkup(content) {
        return { __html: content };
    }

    buildExactMatchesSection(classes) {
        const word = this.props.match.params[0]
        const matchesContent = [];
        if(this.state.errorMsg){
            matchesContent.push(<Typography className={classes.errorMsg}>{this.state.errorMsg}</Typography>);
        }
        else if (this.state.definitionLoading) {
            matchesContent.push(<DefinitionLoader key="definitionLoader" />);
        } else if (this.state.exactMatches.length > 0) {
            this.state.exactMatches.map((value, index) => {
                const wordDefinition = formatToMultiline(value.wordDefinition);
                const wordToVerb = value.verb &&
                    <Typography className={classes.wordToVerb} gutterBottom variant="caption">
                        <span style={{ fontStyle: 'italic' }}> Shih </span>
                        <Link color="primary" component={RouterLink} to={`/${value.verb}`}> {value.verb} </Link>
                    </Typography>;
                matchesContent.push(<Fragment key={`${value.word}${index}`}> <Divider variant="middle" />
                    <ListItem>
                        <ListItemText className={classes.definition} primary={<div dangerouslySetInnerHTML={this.createMarkup(wordDefinition)} />} secondary={wordToVerb} />
                    </ListItem>
                </Fragment>
                );
            });
            /* We display only first exact match's antonyms and synonyms */
            const antonyms = this.state.exactMatches[0].antonyms;
            if (antonyms.length > 0) {
                matchesContent.push(this.buildAntonymsArea(classes, antonyms));
            }
            const synonyms = this.state.exactMatches[0].synonyms;
            if (synonyms.length > 0) {
                matchesContent.push(this.buildSynonymsArea(classes, synonyms));
            }
        }
        else {
            const notFound =
                <Fragment key="notFound">
                    <Divider variant="middle" />
                    <ListItem>
                        <ListItemText className={classes.definition} primary="Nuk u gjet asnjë rezultat!" />
                    </ListItem>
                </Fragment>;
            matchesContent.push(notFound);
        }
        return <React.Fragment>
            <Paper elevation={1}>
                <Card className={classes.cardExact}>
                    <div className={classes.cardDetails}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                                Kuptimi i fjalës <span className={classes.mainWord}>{word}</span>
                            </Typography>
                            {matchesContent}
                            <CardActions className={classes.socialButtonsContainer}>
                                <SocialButtons key="socialButtons"/>
                            </CardActions>
                        </CardContent>
                    </div>
                </Card>
            </Paper>

        </React.Fragment>;
    }

    buildFuzzyMatchesSection(classes) {
        const fuzzyMatchesContent = [];
        if (this.state.definitionLoading) {
            fuzzyMatchesContent.push(<DefinitionLoader key="definitionLoader" />);
        }
        else if (this.state.fuzzyMatches.length > 0) {
            this.state.fuzzyMatches.map((value, index) => {
                const wordDefinition = formatToMultiline(value.wordDefinition);
                fuzzyMatchesContent.push(
                    <Fragment key={`${value.word}${index}`}>
                        <Divider variant="middle" />
                        <ListItem>
                            <ListItemText primary={<Link color="primary" component={RouterLink}
                                                         to={`/${value.word}`}>{value.word}</Link>}
                                          secondary={<span className={classes.fuzzyDefinition}
                                                           dangerouslySetInnerHTML={this.createMarkup(wordDefinition)}/>}/>
                        </ListItem>
                    </Fragment>
                )
            }
            )
        }
        if(fuzzyMatchesContent.length === 0){
            return null;
        }
        return <Paper elevation={1}>
                <Card className={classes.cardSimilar}>
                    <div className={classes.cardDetails}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                                Shih edhe
                                </Typography>
                            {fuzzyMatchesContent}
                        </CardContent>
                    </div>
                </Card>
            </Paper>;
    }
}

export default withStyles(styles)(SearchResultPage);

