const dev = {
    apiDefineUrl: 'https://qx56ck6fp8.execute-api.eu-central-1.amazonaws.com/dev/define',
    apiHighlightUrl: 'https://nd5xo4v7g2.execute-api.eu-central-1.amazonaws.com/dev/highlight',
    apiWordOfTheDayUrl: 'https://f3bto3qy14.execute-api.eu-central-1.amazonaws.com/dev/wordoftheday',
    topTrendingWordsUrl: 'https://fjalorthi-trending-top-words-bucket-dev.s3.eu-central-1.amazonaws.com/top_ga_words.json',
    conjugateVerb: 'https://gc0432fd38.execute-api.eu-central-1.amazonaws.com/dev/conjugateverb',
    apiSearchVerbUrl: 'https://gc0432fd38.execute-api.eu-central-1.amazonaws.com/dev/searchverb',
    gaTrackingCode: 'UA-62542512-4'
};
const prod = {
    apiDefineUrl: 'https://qub10cxllf.execute-api.eu-central-1.amazonaws.com/prod/define',
    apiHighlightUrl: 'https://mpp9acmhh1.execute-api.eu-central-1.amazonaws.com/prod/highlight',
    apiWordOfTheDayUrl: 'https://marxfnwnc2.execute-api.eu-central-1.amazonaws.com/prod/wordoftheday',
    topTrendingWordsUrl: 'https://fjalorthi-trending-top-words-bucket-prod.s3.eu-central-1.amazonaws.com/top_ga_words.json',
    conjugateVerb: 'https://znd35kacq0.execute-api.eu-central-1.amazonaws.com/prod/conjugateverb',
    apiSearchVerbUrl: 'https://znd35kacq0.execute-api.eu-central-1.amazonaws.com/prod/searchverb',
    gaTrackingCode: 'UA-62542512-4'
};

const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;

export default {
    ...config
};