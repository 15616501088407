import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import ContentLoader from "react-content-loader";

export default function MoodCard(props) {
    const classes = useStyles();
    let moods = []
    if(props.loading){
        moods.push(<ConjugationLoader/>);
    }
    else{
        Object.entries(props.mood).map(([key, value]) => {
            const cm = props.mood[key][props.voice];
            let line;
            if(props.moodName === 'IMPERATIVE'){
                line = `${prettify(cm['II_S'])}, ${prettify(cm['II_P'])}`;
            }
            else if(props.isThirdPersonOnly){
                line = `${prettify(cm['III_S'])}, ${prettify(cm['III_P'])}`;
            }
            else{
                line = `${prettify(cm['I_S'])}, ${prettify(cm['II_S'])}, ${prettify(cm['III_S'])}, ${prettify(cm['I_P'])}, ${prettify(cm['II_P'])}, ${prettify(cm['III_P'])}`;
            }
            if(props.isThirdPersonOnly && props.moodName === 'IMPERATIVE'){
                moods.push(<Typography className={classes.tenseLine} variant="body1" component="p">Nuk ka</Typography>);
            }
            else {
                moods.push(<Typography className={classes.tenseLine} variant="body1"
                                       component="p"><strong>{i18n[key]}: </strong>{line}</Typography>);
            }
        });
    }
    return (
        <Paper elevation={2}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {i18n[props.moodName]}
                    </Typography>
                    <Divider light/>
                    <Typography variant="body2" component="p">
                        {moods}
                    </Typography>
                </CardContent>
            </Card>
        </Paper>
    );
}

const useStyles = makeStyles(theme=> ({
    root: {
        minWidth: 275,
        marginBottom: theme.spacing(1)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 18,
    },
    pos: {
        marginBottom: 12,
    },
    tenseLine: {
        marginTop: theme.spacing(1)
    }
}));

const i18n = {
    PRESENT: 'E tashmja',
    IMPERFECT: 'E pakryera',
    PAST_DEFINITE: 'E kryera e thjeshtë',
    PERFECT: 'E kryera',
    PAST_PERFECT: 'Më se e kryera',
    PLUPERFECT: 'E kryera e tejshkuar',
    FUTURE: 'E ardhmja',
    FUTURE_PERFECT: 'E ardhmja e përparme',
    FUTURE_IMPERFECT: 'E ardhmja e së shkuarës',
    FUTURE_PAST_PERFECT: 'E ardhmja e përparme e së shkuarës',
    INDICATIVE: 'Mënyra Dëftore',
    SUBJUNCTIVE: 'Mënyra Lidhore',
    ADMIRATIVE: 'Mënyra Habitore',
    OPTATIVE: 'Mënyra Dëshirore',
    CONDITIONAL: 'Mënyra Kushtore',
    IMPERATIVE: 'Mënyra Urdhërore'
}

export function prettify(line){
    if(line == null){
        return '';
    }
    let result = line;
    let splits = line.split('|')
    let i = 0;
    if (splits.length > 1) {
        result = '';
        for (const split of splits) {
            result += split.trim();
            i++;
            if (i == 1) {
                result += '(';
            } else {
                if (splits.length > i) {
                    result += ', ';
                } else {
                    result += ')';
                }
            }
        }
    }
    return result;
}

export function ConjugationLoader() {
    return (<ContentLoader
        height={160}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="1" y="10" rx="2" ry="2" width="400" height="139" />
    </ContentLoader>)
};