import React, {Fragment} from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import debounce from 'lodash.debounce';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Redirect} from "react-router-dom";
import config from '../config';
import Cookies from 'js-cookie';
import Chip from "@material-ui/core/Chip";
import {Divider} from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";

let theRef = React.createRef();

const fetchSuggestions = (word, type, reqStarted, reqEnded, afterFetchHandler) => {
    let url = `${config.apiDefineUrl}/${word}`;
    if(type === 'verb'){
        url = `${config.apiSearchVerbUrl}/${word}`
    }
    reqStarted()
    axios.get(url)
        .then(function (response) {
            afterFetchHandler(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
            reqEnded();
        });
};

const debouncedSuggestionFetcher = debounce(fetchSuggestions, 1000, {trailing: true});

function WordAutosuggest(props) {
    const classes = useStyles();
    const isVerbSearch = props.type === 'verb';
    const [state, setState] = React.useState({
        word: '',
    });

    const [suggestions, setSuggestions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [shouldChangeRoute, setShouldChangeRoute] = React.useState(false);
    let autosuggestRef = React.createRef();

    const handleSuggestionsFetchRequested = ({ value }) => {
        const inputValue = deburr(value).toLowerCase();
        if(inputValue.length !== 0){
            debouncedSuggestionFetcher(inputValue, props.type, ()=> setLoading(true), () => setLoading(false), response => {
                if (isVerbSearch) {
                    let verbSuggestions = [];
                    Object.keys(response.data).forEach(key => {
                        verbSuggestions.push({verb: key, types: response.data[key]});
                    })
                    setSuggestions(verbSuggestions);
                } else {
                    let mergedMatches = response.data.exactMatches.concat(response.data.fuzzyMatches).slice(0, 5);
                    setSuggestions(mergedMatches);
                }
            });
        }
        else if (!isVerbSearch){
            //just trigger autosuggest
            // const historyCookie = Cookies.get('previously_searched');
            // const historyItems = historyCookie ? JSON.parse(historyCookie): [];
            // historyItems.reverse();
            // let suggestions = [];
            // let i = 0;
            // historyItems.forEach(item => suggestions.push({word: item, index: i++, isHistory: true}));
            // setSuggestions(suggestions);
        }
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleChange = name => (event, { newValue }) => {
        setState({
            ...state,
            [name]: newValue
        });
        setShouldChangeRoute(false);
    };

/*    const shouldRenderSuggestions = () => {
        return false;
    };*/

    const getSuggestionValue = suggestion => isVerbSearch ? suggestion.verb : suggestion.word;

    const handleSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        setShouldChangeRoute(true);
        setSuggestions([]);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setShouldChangeRoute(true);
        theRef.current.blur();
    };

    // const RenderHistorySuggestionsContainer = ({ containerProps, children, query }) => {
    //     return (
    //         <div {...containerProps}>
    //             {
    //                 <div className="footer">
    //                     Historia <strong>{query}</strong>
    //                     {children}
    //                 </div>
    //             }
    //         </div>
    //     )
    // }

    // const renderHistorySuggestion = (suggestion, { query, isHighlighted}) => {
    //     return (
    //         <ListItem className={classes.autosuggestItem} component="div" selected={isHighlighted}>
    //             <ListItemText primary={suggestion.word}/>
    //         </ListItem>);
    // }

    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        if (isVerbSearch) {
            return (
                <Fragment>
                    <ListItem className={classes.autosuggestItem} component="div" selected={isHighlighted}>
                        <ListItemText primary={suggestion.verb}
                                      secondary={suggestion.types.map(item => <Chip size="small"
                                                                                    className={classes.chipVerbType}
                                                                                    label={i18n[item]}></Chip>)}/>
                        <ListItemSecondaryAction>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                Kliko
                            </Link>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider/>
                </Fragment>
            )
        }
        // if(suggestion.isHistory){
        //     return null;
        // }
        const matches = match(suggestion.word, query);
        // console.log(suggestion.wordDefinition);
        let definition = suggestion.wordDefinition.replace(/<span.+?data\-short\="(.+?)".*?\/span>/g, '$1.');
        const definitionCropped = {__html: definition.substring(0, 100) + '...' + '<i class="see-more">kliko për më shumë</i>'};
        return (
            <ListItem className={classes.autosuggestItem} component="div" selected={isHighlighted}>
                <ListItemText primary={suggestion.word}
                              secondary={<span dangerouslySetInnerHTML={definitionCropped}/>}/>
            </ListItem>

        );
    }

    const renderInputComponent = (inputProps) => {
        const { classes, inputRef = () => {}, ref, ...other } = inputProps;
        return (
            <div className={classes.wrapper}>
                <TextField
                    fullWidth
                    autoFocus={isVerbSearch ? true : false}
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                        inputProps: { ref: theRef, onClick: (event) => event.target.select()},
                        disableUnderline: true,
                        classes: {
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        },
                    }}
                    {...other} />
                {loading && <CircularProgress color="primary" size={24} className={classes.searchProgress} />}
            </div>
        );
    }

    const autosuggestProps = {
        alwaysRenderSuggestions: !state.word,
        renderInputComponent,
        suggestions: suggestions,
        onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: handleSuggestionsClearRequested,
        onSuggestionSelected: handleSuggestionSelected,
        getSuggestionValue: getSuggestionValue,
        // renderSuggestionsContainer: RenderHistorySuggestionsContainer,
        renderSuggestion: renderSuggestion,
        focusInputOnSuggestionClick: false,
        highlightFirstSuggestion: isVerbSearch
    };
    return (
        <div className={classes.root}>
            {shouldChangeRoute && <Redirect to={props.type === 'verb'? `/zgjedho/${state.word}` : state.word} push={true} />}
            <form onSubmit={onSubmit}>
                <Autosuggest {...props}
                    {...autosuggestProps}
                    inputProps={{
                        classes,
                        placeholder: `Shkruaj ${isVerbSearch ? 'foljen' : 'fjalën'} këtu`,
                        value: state.word,
                        onChange: handleChange('word'),
                        /*onKeyPress: onKeyPress*/
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                    ref={autosuggestRef}
                />
            </form>
            <div className={classes.divider} />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 160,
            '&:focus': {
                width: 420,
            },
        },
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    autosuggestItem: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    historyItem: {
        margin: theme.spacing(2)
    },
    '@global': {
        '.see-more': {color: '#7D0414'}
    },
    chipVerbType: {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.5)
    },
    wrapper: {
        // margin: theme.spacing(1),
        position: 'relative',
    },
    searchProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '92%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const useHistoryCardStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const i18n = {
    'trans': 'Kalimtare',
    'intrans': 'Jokalimtare',
    'refl': 'Vetvetore',
    'III': 'v. III'
}
export default WordAutosuggest;