import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from '@material-ui/core/styles';
import axios from "axios";
import ContentLoader from "react-content-loader";
import config from '../config';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    title: {
        fontSize: 20,
    },
    fragment: {
        '@global': {
            b: {backgroundColor: '#7D0414', color: 'white', fontSize: 15}
        }
    }
}));

function BooksLoader() {
    return (<ContentLoader
        height={160}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="5" y="10" rx="0" ry="0" width="136" height="15"/>
        <rect x="5" y="35" rx="0" ry="0" width="100%" height="500"/>
    </ContentLoader>)
}

function renderBookItem(book, classes) {
    return <>
        <Divider variant="middle"/>
        <ListItem alignItems="flex-start">
            <ListItemText
                primary={`"${book.title}"`}
                secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            nga {book.author}
                        </Typography>
                        {book.highlightedFragments.map((fragment, index) => {
                            return <span className={classes.fragment} key={index}
                                         dangerouslySetInnerHTML={{__html: fragment}}/>;
                        })}
                    </React.Fragment>
                }
            />
        </ListItem>
    </>;
}

function renderNotFound() {
    return <>
        <Divider variant="middle"/>
        <ListItem alignItems="flex-start">
            <ListItemText secondary="Nuk u gjet në literaturë"/>
        </ListItem>
    </>;
}

function renderLoader() {
    return <><BooksLoader/><BooksLoader/></>;
}

export default function InBooks(props) {
    const allWords = [];
    const exactMatches = props.exactMatches;
    exactMatches.forEach(match => {
        allWords.push(match.word);
        const derivedNo = 9;
        for (let i = 1; i <= derivedNo; i++) {
            const derivedWord = match[`wordDer${i}`];
            if (derivedWord) {
                allWords.push(derivedWord);
            }
        }
    });
    const i = 0;
    const classes = useStyles();
    const [books, setBooks] = React.useState([]);
    const [highlightLoading, setHighlightLoading] = React.useState(true);
    React.useEffect(() => {
        if (allWords.length > 0) {
            setHighlightLoading(true);
            setBooks([]);
            axios.get(`${config.apiHighlightUrl}/${allWords.join()}`)
                .then(function (response) {
                    setBooks(response.data.length > 2 ? response.data.slice(0, 2) : response.data);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    setHighlightLoading(false);
                });
        } else {
            setHighlightLoading(false);
        }
    }, [props.word, props.exactMatches]);

    return <Paper elevation={1}>
            <Card className={classes.card}>
                <div className={classes.cardDetails}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                            Në Literaturë
                        </Typography>
                        {(props.definitionLoading || highlightLoading) ? (
                            <React.Fragment>{renderLoader()}</React.Fragment>) : (
                            <List className={classes.root}>
                                {books.length > 0 ? books.map((book, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {renderBookItem(book, classes)}
                                            </React.Fragment>
                                        )
                                    }) :
                                    (<React.Fragment>
                                            {renderNotFound()}
                                        </React.Fragment>
                                    )}

                            </List>
                        )
                        }

                    </CardContent>
                </div>
            </Card>
        </Paper>;
}