import React from 'react';
import HomePage from "./pages/HomePage";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import SearchResultPage from "./pages/SearchResultPage";
import Header from './components/Header';
import Footer from "./components/Footer";
import withStyles from "@material-ui/core/styles/withStyles";
import config from './config';
import * as ReactGA from "react-ga";
// import CustomSpellCheckPage from "./pages/CustomSpellCheckPage";
import {ConjugationPage} from "./pages/ConjugationPage";

ReactGA.initialize(config.gaTrackingCode);

const styles = theme => ({
    container: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(6))]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    }
});

class Main extends React.Component {
    state = {
        loading: false,
        query: {},
        country: 'Albania'
    };

    render = () => {
        const { classes } = this.props;
        return (
            <Router>
                <div className={classes.container}>
                    <Switch>
                        <Route path="/zgjedho*"
                               render={(props) => <ConjugationPage {...props} query={this.state.query}
                                                                   loading={this.state.loading}/>}/>
                        <Route path="/">
                            <Header />
                            <main>
                                <Switch>
                                    <Route exact path="/" render={(props) => <HomePage {...props} countrySetHandler={this.countrySetHandler} />} />
                                    {/*<Route exact path="/drejtshkrimori" render={(props) => <CustomSpellCheckPage {...props} />} />*/}
                                    {/*<Route exact path="/drejtshkrimori" render={(props) => <CustomSpellCheckPageMUI {...props} />} />*/}
                                    <Route path="/sitemap.xml" onEnter={() => window.location.reload()} />
                                    <Route path="/sitemap/*" onEnter={() => window.location.reload()} />
                                    <Route exact path="/*"
                                           render={(props) => <SearchResultPage {...props} query={this.state.query}
                                                                                loading={this.state.loading}/>}/>
                                </Switch>
                            </main>
                        </Route>
                    </Switch>
                    <Footer />
                </div>
            </Router>
        );
    }
}

export default withStyles(styles)(Main);
