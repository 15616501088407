export function formatToMultiline(wordDefinition) {
    return wordDefinition.replace(/(\s\d+\.)/gm, '\n$1');
}

export function extractExcerptFromRichText(richDefinition, maxLength) {
    let offset = 60;
    let re = /<\/.+?>/g;
    let match;
    let max = maxLength;
    let minMatch = -1;
    while ((match = re.exec(richDefinition)) != null) {
        if (minMatch == -1) {
            minMatch = match.index;
        }
        if (match.index > maxLength) {
            max = match.index;
            break;
        }
    }
    if (max > maxLength + offset) {
        if (minMatch < maxLength - offset) {
            max = maxLength + offset;
        } else {
            max = maxLength;
        }
    }
    if (richDefinition.length >= max && max <= maxLength + offset) {
        return richDefinition.substring(0, max);
    } else {
        return richDefinition;
    }
}